import request from '@/utils/request.js'

// 用户登录接口
export const login = (data) => {
  return request({
    method: 'POST',
    url: 'login',
    data
  })
}

// 查询用户接口
export const getUserInfo = (data) => {
  return request({
    method: 'GET',
    url: 'user',
    params: {
      ...data
    }
  })
}

// 查询用户列表
export const getUserList = (data) => {
  return request({
    method: 'GET',
    url: 'user',
    params: {
      ...data
    }
  })
}
