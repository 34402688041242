<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>账户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="account-header" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button style="float: right" type="primary" @click="handleAdd">新增账户</el-button>
        <el-form :inline="true" :model="searchAccountForm" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="searchAccountForm.accountName" placeholder="输入登录用户名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 当前项目信息展示 -->
      <el-table
      :data="accountTableData"
      style="width: 100%">
      <el-table-column
      type="index"
      width="35">
    </el-table-column>
      <el-table-column
        prop="nick_name"
        label="用户昵称">
      </el-table-column>
      <el-table-column
        prop="id"
        label="用户ID"
        width="80">
      </el-table-column>
      <el-table-column
        prop="username"
        label="登录用户名">
      </el-table-column>
      <el-table-column
        prop="email_addr"
        label="邮箱">
      </el-table-column>
      <el-table-column
        prop="role_id"
        label="角色">
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag :type="{'1': 'success','0': 'danger','2': 'warning'}[scope.row.status]">
            {{ scope.row.status === '1' ? '启用' : '禁用'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间">
      </el-table-column>
    </el-table>

    </el-card>

    <!-- 测试套件分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 5, 10, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="this.pageInfo.total_count">
    </el-pagination>

  </div>
</template>

<script>
import { getUserList } from '@/services/user.js'
export default {
  name: 'Account',
  data () {
    return {
      sdsd: 'sd',
      accountTableData: [],
      pageInfo: {
        size: 10,
        currentPage: 1,
        total_count: null
      },
      searchAccountForm: {
        accountName: ''
      }
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    async getUserList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await getUserList(queryObj)
      if (res.status === 1) {
        this.accountTableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    handleAdd () {
      console.log('asd')
    },
    handleSearch () {
      console.log('asd')
    },
    // 账户列表信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.getUserList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.getUserList()
      console.log(`当前页: ${val}`)
    }
  }

}
</script>

<style lang="scss" scoped>
    .account-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
    }

</style>
